.filters {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 24px;

  .left {
    flex: 1 1;
  }
  .right {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;
  }

}

.expanded {
  display: flex;
  gap: 40px;
  .list {
    li {
      list-style-type: none;
      font-weight: bold;
      //display: grid;
      //grid-template-columns: 1fr 1fr;
      span {
        display: inline-block;
        text-wrap: nowrap;
        font-weight: normal;
      }
    }
  }
}
