@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  --color-white: #FFFFFF;
  --color-text: #29393D;
  --color-brand: #00A6CE;
  --color-action: #5CD6CE;
  --color-background-one: #E2F2FD;
  --color-background-two: #DBF6F4;
  --color-background-three: #DBF6E4;
  --color-border: #E2E5E9;
  --color-green: #47D780;
  --color-danger: #CE003E;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-width: 320px;
  margin: 0;
  background-color: var(--color-white);
  font-family: 'Inter', sans-serif;
  font-stretch: normal;
  font-weight: normal;
  color: var(--color-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--color-brand);
}
a:visited {
  color: var(--color-brand);
}

.bold {
  font-weight: bold;
}

.content {
  padding: 0 20px;
}
.form {
  width: 100%;
  max-width: 600px;
  margin: auto;
  h2 {
    margin: 32px 0;
  }
}

.caption {
  font-size: 12px;
}

.block {
  display: block;
  width: 100%;
}

.danger {
  color: var(--color-danger);
}